// export const BASE_URL = 'https://edunix-api.beetronix.com/';
// export const BASE_URL = 'https://api.alnukhbeh-edu.com/'
// export const BASE_URL = 'https://syria-flowers.beetronix.com/'
// export const BASE_URL = 'https://api.mirror.edunixsystem.com/'
export const BASE_URL = 'https://api.alawal.edunixsystem.com'

export const IMAGE_URL = 'https://api.alawal.edunixsystem.com/files/logo.png ';
// export const IMAGE_URL = 'https://api.ags-sy.com/files/logo.png';

export const VERSION = '5.0.0';
